<template>
  <div class="pa-5" style="width:100%">
    <ConfirmPassword
      v-if="isAuthConfirmed === false"
      @confirmed="isAuthConfirmed = true"
    />
    <div v-if="isAuthConfirmed">
      <Mypage />
    </div>
  </div>
</template>

<script>
import ConfirmPassword from './components/ConfirmPassword.vue';
import Mypage from './components/Mypage.vue';

export default {
  components: {
    ConfirmPassword,
    Mypage
  },
  data() {
    return {
      isAuthConfirmed: false
    };
  },
  methods: {}
};
</script>

<style></style>
