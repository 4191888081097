<template>
  <div class="d-flex align-center justify-cetner" style="height: 500px">
    <v-card class="round-lg ma-auto pa-5" width="450">
      <div class="d-block text-center">
        <h3>개인정보 보호를 위해 비밀번호를 한번 더 입력해주세요.</h3>
        <ValidationObserver ref="confirmUserAuthRef">
          <ValidationProvider
            v-slot="{ errors }"
            :rules="{
              required: true,
              min: 6
            }"
          >
            <v-text-field
              v-model="password"
              persistent-placeholder
              placeholder="비밀번호를 입력해주세요."
              :error-messages="errors"
              outlined
              type="password"
            >
            </v-text-field>
          </ValidationProvider>
          <v-btn color="secondary" @click.stop="submit" :loading="loading">
            확인
          </v-btn>
        </ValidationObserver>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { confirmPasswordApi } from '@/api/partner/UserAPI';
export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      password: null,
      loading: false
    };
  },
  methods: {
    async submit() {
      this.loading = true;
      const valid = await this.$refs.confirmUserAuthRef.validate();
      if (valid) {
        const params = {
          password: this.password
        };
        const [error, data] = await confirmPasswordApi(params);
        if (error) {
          console.error(error);
          this.$axiosErrorAlert();
        } else {
          if (data.success) {
            this.$emit('confirmed');
          } else {
            this.$Swal.fire({
              icon: 'error',
              html: `<h3>비밀번호를 확인해주세요.</h3>`,
              timer: 1000,
              showConfirmButton: false
            });
          }
        }
      }
      this.loading = false;
    }
  }
};
</script>

<style></style>
