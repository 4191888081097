import { getCamelKeys } from '@/utils/functions';

export class User {
  uid = '';
  email = '';
  password = '';
  password_confirmation = '';
  businessName = '';
  managerName = '';
  managerTel = '';
  businessNum = '';
  businessPostcode = '';
  businessDetailAddress = '';
  businessExtraAddress = '';
  businessImageId = '';
  businessImageFile = '';
  bankName = '';
  bankAccountNum = '';
  bankAccountHolder = '';
  bankbookImageId = '';
  bankbookImageFile = '';

  constructor(init = null) {
    if (!init) return;

    const covObject = getCamelKeys(init);
    const initKeys = Object.keys(covObject);

    for (const key in this) {
      if (initKeys.includes(key)) {
        let val = covObject[key];

        this[key] = val;
      }
    }
  }
}

export class KakaoAddress {
  constructor(address, postcode, detailAddress, extraAddress) {
    this.address = address;
    this.postcode = postcode;
    this.detailAddress = detailAddress;
    this.extraAddress = extraAddress;
  }
}
