<template>
  <div>
    <v-row dense class="ma-0 mt-3">
      <h2 cols="auto" class="col-auto title font-weight-medium">기본정보</h2>

      <v-col class="text-right ml-auto" cols="auto" sm="12" md="auto">
        <v-btn
          color="secondary"
          outlined
          :loading="loading"
          :style="{ marginLeft: '10px' }"
          @click="editPasswordDialog = true"
        >
          비밀번호 변경
        </v-btn>
      </v-col>
    </v-row>

    <ValidationObserver :ref="refKey">
      <v-row dense>
        <v-col cols="12" lg="6">
          <ValidationProvider v-slot="{ errors }" rules="required">
            <v-text-field
              v-model="user.uid"
              label="아이디"
              outlined
              :error-messages="errors"
              required
              dense
              readonly
              disabled
              filled
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" lg="6">
          <ValidationProvider v-slot="{ errors }" rules="required|email">
            <v-text-field
              v-model="user.email"
              label="이메일 주소"
              outlined
              type="email"
              :error-messages="errors"
              required
              dense
              readonly
              disabled
              filled
            />
          </ValidationProvider>
        </v-col>
      </v-row>
      <hr style="margin-top: 20px; border: dashed 1px #ddd" />

      <div class="my-5 text-center">
        <!-- <v-btn v-if="!editable" color="secondary" @click="editable = true">
          수정하기
        </v-btn>
        <v-btn
          v-if="editable"
          color="secondary"
          @click="handleUpdateClick"
          :loading="loading"
          >적용하기</v-btn
        >
        <v-btn
          v-if="editable"
          color="secondary"
          outlined
          class="ml-2"
          @click="editable = false"
          >취소</v-btn
        > -->
      </div>
    </ValidationObserver>

    <ChangePasswordDialog
      v-if="editPasswordDialog"
      :originPassword="user.password"
      :dialog="editPasswordDialog"
      @changePassword="handleChangePassword"
      @close="editPasswordDialog = false"
    />
  </div>
</template>

<script>
import { BankList } from '@/config/charge';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
// import ImageFileInput from '@/components/ImageFileInput.vue';
// import { KakaoAddress } from '@/model/register/KakaoAddress';
import { User } from '@/model/partner/User';
import { convertFormData } from '@/utils/functions';
import ChangePasswordDialog from '@/components/common/ChangePasswordDialog.vue';
import {
  updateProfileApi,
  changePasswordApi,
  getProfileApi
} from '@/api/partner/UserAPI';

export default {
  components: {
    ChangePasswordDialog,
    ValidationProvider,
    ValidationObserver
    // ImageFileInput
  },
  data() {
    return {
      refKey: 'mypageRef',
      user: new User(),
      kakaoAddress: null,
      editable: false,
      item: {},
      loading: false,
      editPasswordDialog: false
    };
  },
  computed: {
    isPasswordConfirmRequired() {
      const password = this.user?.password || '';
      return password.length > 0;
    },
    BankList() {
      return BankList;
    }
  },
  mounted() {
    this.fetchProfile();
  },
  methods: {
    async handleUpdateClick() {
      this.loading = true;
      // 입력값의 유효성을 확인합니다.
      const valid = await this.$refs[this.refKey].validate();
      if (valid) {
        const option = {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        };

        const [error] = await updateProfileApi(
          convertFormData(this.user),
          option
        );
        if (error) {
          console.log(error);
          this.$axiosErrorAlert();
        } else {
          this.$router.go();
        }
      }
      this.loading = false;
    },
    async fetchProfile() {
      const [error, data] = await getProfileApi();
      if (error) {
        console.error(error);
      } else {
        const { user } = data;

        const newUser = new User(user);
        this.user = newUser;
      }
    },
    async handleChangePassword(params) {
      const [error] = await changePasswordApi(params);
      let result = 'error';
      let message = '변경 실패';
      let description = '비밀번호 변경에 실패했습니다.';

      if (error) {
        console.error(error);
      } else {
        result = 'success';
        message = '변경 성공';
        description = '비밀번호가 변경되었습니다.';

        this.editPasswordDialog = false;
        this.user.password = params.password;
      }
      // 알림 노티
      this.$notification[result]({
        message: message,
        description: description
      });
    }
  }
};
</script>

<style></style>
